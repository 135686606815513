/* PrivacyPolicy.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #2A4365;
  --accent-color: #F6AD55;
  --text-color: #2D3748;
  --background-color: #F7FAFC;
  --border-color: #E2E8F0;
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.65;
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
}

.privacy-policy-container {
  max-width: 1280px;
  margin: 2rem auto;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
}

/* Header styles */
.privacy-header {
  background-color: #E9B949; /*#E9B949;*/
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  border-bottom: 4px solid var(--accent-color);
}

.logo-container {
  margin-bottom: 2rem;
}

.logo {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.emoji {
  font-size: 2.5rem;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.privacy-header h1 {
  font-family: 'Libre Baskerville', serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  letter-spacing: -0.02em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.last-updated {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.85);
  letter-spacing: 0.02em;
}

/* Content styles */
.privacy-content {
  padding: 3rem 2rem;
}

/* Desktop layout */
.desktop-layout {
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 4rem;
}

.table-of-contents {
  position: sticky;
  top: 2rem;
  align-self: start;
  height: fit-content;
  padding: 1.5rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.table-of-contents h3 {
  font-family: 'Libre Baskerville', serif;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid var(--border-color);
  color: var(--primary-color);
}

.table-of-contents ul {
  list-style: none;
}

.table-of-contents li {
  margin-bottom: 0.75rem;
  position: relative;
}

.table-of-contents a {
  text-decoration: none;
  color: var(--text-color);
  display: block;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500;
}

.table-of-contents a:hover {
  color: var(--primary-color);
  background: rgba(246, 173, 85, 0.08);
  transform: translateX(4px);
}

.table-of-contents a.active {
  color: var(--primary-color);
  background: rgba(246, 173, 85, 0.12);
  font-weight: 600;
}

.sections-content {
  max-width: 800px;
}

.policy-section {
  margin-bottom: 3.5rem;
  scroll-margin-top: 2rem;
}

.policy-section h2 {
  font-family: 'Libre Baskerville', serif;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  color: var(--primary-color);
  padding-bottom: 0.75rem;
  border-bottom: 2px solid var(--border-color);
  position: relative;
}

.policy-section h2::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 80px;
  height: 2px;
  background: var(--accent-color);
}

.policy-section h3 {
  font-size: 1.3rem;
  margin: 2rem 0 1rem;
  color: var(--primary-color);
  font-weight: 600;
}

.policy-section p {
  margin-bottom: 1.25rem;
  color: var(--text-color);
  font-size: 1rem;
}

.policy-section ul {
  padding-left: 1.75rem;
  margin-bottom: 1.5rem;
}

.policy-section li {
  margin-bottom: 0.75rem;
  padding-left: 0.5rem;
  position: relative;
}

.policy-section li::before {
  content: '•';
  color: var(--accent-color);
  position: absolute;
  left: -1rem;
  font-weight: 700;
}

.policy-section a {
  color: var(--primary-color);
  text-decoration: none;
  border-bottom: 2px solid rgba(246, 173, 85, 0.3);
  transition: all 0.2s ease;
}

.policy-section a:hover {
  border-bottom-color: var(--accent-color);
}

/* Mobile accordion */
.mobile-accordion {
  width: 100%;
}

.accordion-section {
  margin-bottom: 1rem;
  border: 2px solid var(--border-color);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.25s ease;
}

.accordion-section:hover {
  border-color: var(--accent-color);
}

.accordion-button {
  width: 100%;
  background-color: #fff;
  border: none;
  text-align: left;
  padding: 1.25rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
}

.accordion-button:hover {
  background-color: rgba(246, 173, 85, 0.05);
}

.accordion-button.active {
  color: var(--primary-color);
  background-color: rgba(246, 173, 85, 0.1);
}

.accordion-icon {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin-left: 1rem;
  transition: transform 0.3s ease;
}


.accordion-content {
  padding: 0 1.25rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.accordion-content.active {
  padding: 1.25rem;
  max-height: 5000px;
}

/* Footer styles */
.privacy-footer {
  background-color: var(--primary-color);
  color: #fff;
  padding: 3rem 2rem;
  text-align: center;
  margin-top: 4rem;
}

.footer-links {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.footer-links a {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.footer-links a:hover {
  color: var(--accent-color);
  transform: translateY(-1px);
}

.social-icons {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.social-icon {
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.social-icon:hover {
  background-color: var(--accent-color);
  transform: translateY(-2px);
}

.privacy-footer p {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.75);
  margin-top: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .desktop-layout {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .table-of-contents {
    position: static;
    box-shadow: none;
    padding: 0;
    background: transparent;
  }
}

@media (max-width: 768px) {
  .privacy-policy-container {
    margin: 0 auto;
    border-radius: 0;
  }
  
  .privacy-header {
    padding: 3rem 1.5rem;
  }
  
  .privacy-header h1 {
    font-size: 2rem;
  }
  
  .privacy-content {
    padding: 2rem 1.5rem;
  }
  
  .footer-links {
    flex-direction: column;
    gap: 1rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.policy-section {
  animation: fadeIn 0.4s ease forwards;
}

/* Print styles */
@media print {
  .privacy-policy-container {
    box-shadow: none;
    border-radius: 0;
  }
  
  .table-of-contents,
  .privacy-footer {
    display: none;
  }
}