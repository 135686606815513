/* exit-responsive.css */

.exit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
  }
  
  .exit-message {
    color: white;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin: 1rem 0;
    padding: 0 1rem;
  }
  
  .poll-results-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1rem 0;
    width: 100%;
    padding: 0 1rem;
  }
  
  .poll-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .poll-icon {
    width: 2rem;
    height: 2rem;
    border: 1px solid white;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .poll-percentage {
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45));
    padding: 0.5rem 1rem;
    border-radius: 0.625rem;
    color: white;
    font-weight: 700;
  }
  
  .tag-container {
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 1.25rem;
    margin: 0.75rem 0 1.5rem;
    max-width: min(85%, 400px);
    text-align: center;
  }
  
  .tag-text {
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0 1rem;
    white-space: nowrap;
    font-family: "Inter", sans-serif;
  }
  
  .menu-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.75rem;
    cursor: pointer;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu-dropdown {
    position: absolute;
    top: 4rem;
    right: 1rem;
    background-color: #000;
    border-radius: 1.25rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 120;
    width: 10rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .menu-item {
    width: 100%;
    border-bottom: 1px solid grey;
    padding-bottom: 0.75rem;
    margin-bottom: 0.5rem;
    text-align: center;
    cursor: pointer;
  }
  
  .menu-text {
    color: #FFF;
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .custom-input {
    border-radius: 0.75rem;
    background-color: rgba(255, 255, 255, 0.333);
    width: 100%;
    max-width: 400px;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    border: none;
    margin-top: 2rem;
    color: #fff;
  }
  
  .custom-input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .action-button {
    margin-top: 1.5rem;
    padding: 1rem 3rem;
    border-radius: 2rem;
    color: #ffffff;
    border: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .action-button:active {
    transform: scale(0.98);
  }
  
  .instagram-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
  }
  
  .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    background-color: rgba(255, 255, 255, 0.36);
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    align-self: flex-start;
  }
  
  .back-button:active {
    transform: scale(0.95);
  }
  .prank-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .prank-title {
    color: white;
    font-weight: 900;
    font-size: clamp(1.7rem, 5vw, 2em);
    margin: 1rem 0;
    text-align: center;
    padding: 0 1rem;
  }
  /* Media Queries */
  
  /* Small devices (phones) */
  @media only screen and (max-width: 600px) {
    .poll-results-container {
      flex-direction: column;
      gap: 0.75rem;
    }
    
    .menu-dropdown {
      width: 8rem;
      right: 0.5rem;
    }
    
    .menu-text {
      font-size: 1rem;
    }
    
    .exit-message {
      font-size: 1rem;
    }
  }
  
  /* Medium devices (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 960px) {
    .poll-results-container {
      justify-content: space-around;
    }
  }
  
  /* Large devices (desktops, laptops) */
  @media only screen and (min-width: 961px) {
    .exit-container {
      padding: 2rem;
    }
    
    .custom-input {
      padding: 1.25rem 1.5rem;
      font-size: 1.1rem;
    }
    
    .action-button {
      padding: 1.25rem 3.5rem;
      font-size: 1.1rem;
    }
  }