.header {
    display: flex;
    justify-content: center;
    background-color: transparent;
    align-items: center;
    width: 100%;
    padding: 10px 0;
}

.header-image {
    width: 80%;
    height: 60px;
    object-fit: contain;
    /* Ensures the whole image is visible */
}