
   /* CommunityGuidelines.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

:root {
  --primary-color: #2A4365;
  --accent-color: #F6AD55;
  --text-color: #2D3748;
  --background-color: #F7FAFC;
  --border-color: #E2E8F0;
}

.guidelines-container {
  max-width: 1280px;
  margin: 2rem auto;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
}

.guidelines-header {
  background-color: #E9B949; /* Teal color for guidelines to differentiate from Terms */
  padding: 4rem 2rem;
  text-align: center;
  border-bottom: 4px solid var(--accent-color);
}



.guidelines-header h1 {
  font-family: 'Libre Baskerville', serif;
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1rem;
}

.guidelines-header {
  background: linear-gradient(135deg, #E9B949 0%, #F6AD55 100%);
  padding: 3rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.guidelines-header::before, .guidelines-header::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.guidelines-header::before {
  top: -150px;
  left: -100px;
}

.guidelines-header::after {
  bottom: -150px;
  right: -100px;
}

.guidelines-header h1 {
  font-size: 2.8rem;
  color: #fff;
  margin-bottom: 0.5rem;
  font-weight: 700;
  position: relative;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-subtitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  position: relative;
  z-index: 1;
}
.last-updated {
  color: rgba(255, 255, 255, 0.9);
  font-style: italic;
}

.guidelines-content {
  padding: 3rem 2rem;
}

/* Mobile Accordion Styles */
.mobile-accordion .accordion-section {
  margin-bottom: 1rem;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}

.accordion-button {
  width: 100%;
  padding: 1.25rem;
  background-color: #fff;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.accordion-button:hover {
  background-color: #f8f9fa;
}

.accordion-button.active {
  background-color: var(--primary-color);
  color: white;
}

.accordion-icon {
  font-size: 1.5rem;
  font-weight: 400;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 1.25rem;
}

.accordion-content.active {
  max-height: 2000px; /* Arbitrarily large value */
  padding: 1.25rem;
}

/* Desktop Layout Styles */
.desktop-layout {
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 2rem;
}

.table-of-contents {
  position: sticky;
  top: 2rem;
  align-self: start;
  padding: 1.5rem;
  background-color: var(--background-color);
  border-radius: 8px;
  border-left: 4px solid var(--primary-color);
}

.table-of-contents h3 {
  font-family: 'Libre Baskerville', serif;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
}

.table-of-contents ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.table-of-contents li {
  margin-bottom: 1rem;
}

.table-of-contents a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 0.95rem;
  padding: 0.5rem 0;
  display: block;
  border-left: 2px solid transparent;
  padding-left: 1rem;
  transition: all 0.3s ease;
}

.table-of-contents a:hover,
.table-of-contents a.active {
  color: var(--primary-color);
  border-left: 2px solid var(--primary-color);
  font-weight: 500;
}

.sections-content {
  padding: 0 1rem;
}

.policy-section {
  margin-bottom: 3rem;
  scroll-margin-top: 2rem;
}

.policy-section h2 {
  font-family: 'Libre Baskerville', serif;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  position: relative;
  padding-bottom: 0.75rem;
}

.policy-section h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: var(--accent-color);
}

.policy-section h3 {
  font-size: 1.25rem;
  margin: 1.5rem 0 0.75rem;
  color: #4A5568;
}

.policy-section p, 
.policy-section li {
  line-height: 1.7;
  margin-bottom: 1rem;
}

.policy-section ul, 
.policy-section ol {
  padding-left: 1.25rem;
  margin-bottom: 1.5rem;
}

.policy-section li {
  margin-bottom: 0.5rem;
}

/* Footer Styles */
.guidelines-footer {
  background-color: var(--primary-color);
  color: white;
  padding: 3rem 2rem;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--accent-color);
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.social-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.social-icon:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .guidelines-header {
    padding: 3rem 1.5rem;
  }
  
  .guidelines-header h1 {
    font-size: 2rem;
  }
  
  .guidelines-content {
    padding: 2rem 1rem;
  }
  
  .footer-links {
    flex-direction: column;
    gap: 1rem;
  }
}

/* Make sure content is visible in the accordion */
.mobile-accordion .accordion-content p,
.mobile-accordion .accordion-content ul,
.mobile-accordion .accordion-content ol,
.mobile-accordion .accordion-content h3 {
  opacity: 1;
  transition: opacity 0.3s ease 0.1s;
}

.mobile-accordion .accordion-content:not(.active) p,
.mobile-accordion .accordion-content:not(.active) ul,
.mobile-accordion .accordion-content:not(.active) ol,
.mobile-accordion .accordion-content:not(.active) h3 {
  opacity: 0;
}

/* Strong tag styling */
strong {
  font-weight: 600;
  color: #2C5282;
}