.ask-out-button {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    padding: 15px;
    background-color: yellow;
    color: black;
    border: none;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.ask-out-button.clicked {
    background-color: black;
    color: white;
}