.circular-border-container {
    position: relative;
    width: 160px;
    /* Adjust as needed */
    height: 160px;
    /* Adjust as needed */
    border-radius: 50%;
    padding: 12px;
    margin-top: 25px;
    /* Make the container circular */
  
}

.circular-border-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    /* Make the image circular */
}

.circular-border-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 7px solid #FFF;
    border-radius: 50%;
    box-sizing: border-box;
}

.love-icon {
    position: absolute;
    bottom: 22px;
    right: 0;
    z-index: 100;
    width: 35px;  /* Adjust size */
    height: 35px; /* Adjust size */
}
