.server-error-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.server-error-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  width: min(90%, 900px);
  font-weight: 800;
  text-align: center;
  color: white;
  margin: 5;
  z-index: 8;
  line-height: 1.2;
}

.server-error-highlight {
  color: #FFE500;
}

.server-error-gif-container {
  width: min(90%, 800px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.server-error-gif {
  width: 100%;
  border-radius: 50px;
  margin-top: -30px;
  object-fit: cover;
}

.server-error-overlay-text {
  position: absolute;
  bottom: 20px;
  left: 30px;
  color: white;
  font-size: clamp(1.2rem, 3vw, 2.5rem);
  font-weight: 800;
  margin: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.server-error-footer {
  font-size: clamp(1rem, 2vw, 1.5rem);
  width: min(90%, 800px);
  font-weight: 700;
  text-align: center;
  color: white;
  font-style: italic;
  margin: 0;
  z-index: 2;
  line-height: 1.4;
}

/* Landscape-specific adjustments */
@media (orientation: landscape) {
  .server-error-container {
    justify-content: center;
    gap: 2vh;
    padding: 10px 20px;
  }

  .server-error-heading {
    font-size: clamp(1.5rem, 3.5vw, 3rem);
    margin-bottom: 2vh;
  }

  .server-error-gif-container {
    max-width: 600px;
    max-height: 30vh;
    margin: 20vh 0;
  }

  .server-error-gif {
    margin-top: 0;
    border-radius: 25px;
    object-fit: contain;
  }

  .server-error-overlay-text {
    bottom: -155px;
    left: 150px;
    font-size: clamp(1rem, 2vw, 1.8rem);
  }

  .server-error-footer {
    font-size: clamp(0.9rem, 1.8vw, 1.3rem);
    margin-top: 4vh;
    max-width: 700px;
  }
}