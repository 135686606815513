/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: yellow;
    padding: 20px;
    border-radius: 30px;
    text-align: center;
    width: 60%;
    max-width: 400px;
}

.modal-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.modal-image {
    background-size: cover;
    background-position: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid black;
    padding: 5px;
    box-sizing: border-box;
}

.modal-content h2 {
    margin: 20px 0 10px;
}

.modal-content p {
    margin: 10px 0 20px;
}

.modal-button {
    background-color: black;
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
}

.modal-cancel {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
}