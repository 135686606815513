@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.faq-container {
  max-width: 900px;
  margin: 3rem auto;
  background-color: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.faq-header {
  background: linear-gradient(135deg, #E9B949 0%, #F6AD55 100%);
  padding: 3rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.faq-header::before, .faq-header::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.faq-header::before {
  top: -150px;
  left: -100px;
}

.faq-header::after {
  bottom: -150px;
  right: -100px;
}

.faq-header h1 {
  font-size: 2.8rem;
  color: #fff;
  margin-bottom: 0.5rem;
  font-weight: 700;
  position: relative;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-subtitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  position: relative;
  z-index: 1;
}

.faq-search {
  margin: -25px auto 20px;
  position: relative;
  max-width: 80%;
  z-index: 2;
}

.faq-search input {
  width: 100%;
  padding: 15px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  padding-left: 50px;
  transition: all 0.3s ease;
}

.faq-search input:focus {
  outline: none;
  box-shadow: 0 5px 25px rgba(233, 185, 73, 0.3);
}

.search-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  width: 20px;
  height: 20px;
}

.faq-content {
  padding: 2rem;
}

/* Accordion Styles */
.accordion-section {
  margin-bottom: 1.25rem;
  border-radius: 12px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: all 0.3s ease;
}

.accordion-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

.accordion-section.active {
  border-left: 4px solid #E9B949;
}

.accordion-button {
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  color: #2A4365;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
}

.accordion-button:hover {
  background-color: #f8f9fa;
}

.accordion-button.active {
  background-color: #fff;
  color: #E9B949;
}

.accordion-icon {
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  color: #aaa;
}

.accordion-button.active .accordion-icon {
  color: #E9B949;
}

.accordion-content {
  max-height: 0;
  opacity: 0;
  padding: 0 1.5rem;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.3s ease;
}

.accordion-content.active {
  max-height: 1000px;
  opacity: 1;
  padding: 0.5rem 1.5rem 1.5rem;
}

.accordion-content p {
  color: #666;
  line-height: 1.6;
  font-size: 1rem;
}

/* Categories section */
.faq-categories {
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.faq-categories h3 {
  margin-bottom: 1.5rem;
  color: #2A4365;
  font-weight: 600;
}

.category-pills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.category-pill {
  padding: 0.6rem 1.2rem;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 50px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.category-pill:hover {
  background-color: #E9B949;
  color: white;
  border-color: #E9B949;
  transform: translateY(-2px);
}

/* CTA Section */
.cta-section {
  padding: 3rem 2rem;
  text-align: center;
  background: linear-gradient(to right, #f8f9fa, #f1f3f5);
}

.cta-section h3 {
  font-size: 1.8rem;
  color: #2A4365;
  margin-bottom: 0.5rem;
}

.cta-section p {
  margin-bottom: 1.5rem;
  color: #666;
}

.contact-button {
  display: inline-block;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #E9B949 0%, #F6AD55 100%);
  color: white;
  border-radius: 50px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(233, 185, 73, 0.3);
}

.contact-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(233, 185, 73, 0.4);
}

/* Footer */
.faq-footer {
  padding: 2rem;
  text-align: center;
  background-color: #2A4365;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.faq-footer p {
  margin-bottom: 0.5rem;
}

.faq-footer a {
  color: #E9B949;
  text-decoration: none;
  transition: color 0.2s ease;
}

.faq-footer a:hover {
  color: #F6AD55;
  text-decoration: underline;
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.social-icon {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.2s ease;
}

.social-icon:hover {
  color: #E9B949;
  transform: translateY(-2px);
}

/* Responsive styles */
@media (max-width: 768px) {
  .faq-header h1 {
    font-size: 2.2rem;
  }
  
  .faq-search {
    max-width: 90%;
  }
  
  .accordion-button {
    padding: 1.2rem;
    font-size: 1rem;
  }
  
  .category-pills {
    flex-direction: column;
    align-items: center;
  }
  
  .category-pill {
    width: 80%;
  }
}

/* Animation for accordion */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.accordion-content.active p {
  animation: fadeIn 0.5s ease forwards;
}