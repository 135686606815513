.ask-out-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.circular-image {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center;
    border: 2px solid yellow;
    border-radius: 50%;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 5px;
    /* Gap between border and image */
}

.ask-out-textarea {
    width: 70%;
    height: 100px;
    background: transparent;
    border: transparent;
    border-radius: 10px;
    padding: 10px;
    color: grey;
    margin-bottom: 20px;
    resize: none;
    font-size: 16px;
    outline: none;
}

.ask-out-button {
    width: 70%;
    padding: 15px;
    background-color: yellow;
    color: black;
    border: none;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.ask-out-button.disabled {
    background-color: grey;
    color: black;
    cursor: not-allowed;
}