/* Background with a sleek yellow gradient */
.contact-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #FFD700, #FFAE42);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    text-align: center;
}

/* Contact Title */
.contact-title {
    font-size: 3rem;
    font-weight: 800;
    color: #171616;
    margin-bottom: 40px;
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
}

/* Contact Card Grid */
.contact-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    max-width: 1100px;
    justify-content: center;
}

/* Contact Card Styling */
.contact-card {
    background: rgba(255, 255, 255, 0.2); /* Glass effect */
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    padding: 35px;
    width: 340px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
}

/* Hover Effect */
.contact-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.3);
}

/* Icon Styling */
.icon-wrapper {
    background: rgba(255, 255, 255, 0.3);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto 20px;
}

.contact-icon {
    font-size: 2rem;
    color: #FF5733;
}

/* Card Title */
.card-title {
    font-size: 1.9rem;
    font-weight: 900;
    color: #333;
    margin-bottom: 10px;
}

/* Card Description */
.card-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
}

/* Contact Link */
.contact-link {
    font-size: 0.9rem;
    font-weight: 600;
    color: #FF5733;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

/* Hover Effect for Link */
.contact-link:hover {
    color: #D84315;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-title {
        font-size: 2.5rem;
    }

    .contact-cards {
        flex-direction: column;
        align-items: center;
    }

    .contact-card {
        width: 90%;
    }
}
