   /* TermsOfService.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

:root {
  --primary-color: #2A4365;
  --accent-color: #F6AD55;
  --text-color: #2D3748;
  --background-color: #F7FAFC;
  --border-color: #E2E8F0;
}

.terms-of-service-container {
  max-width: 1280px;
  margin: 2rem auto;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
}

.terms-header {
  background-color: #E9B949;
  padding: 4rem 2rem;
  text-align: center;
  border-bottom: 4px solid var(--accent-color);
}

.terms-header h1 {
  font-family: 'Libre Baskerville', serif;
  font-size: 2.5rem;
  color: #fff;
}


.terms-header {
  background: linear-gradient(135deg, #E9B949 0%, #F6AD55 100%);
  padding: 3rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.terms-header::before, .terms-header::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.terms-header::before {
  top: -150px;
  left: -100px;
}

.terms-header::after {
  bottom: -150px;
  right: -100px;
}

.terms-header h1 {
  font-size: 2.8rem;
  color: #fff;
  margin-bottom: 0.5rem;
  font-weight: 700;
  position: relative;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-subtitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  position: relative;
  z-index: 1;
}
.terms-content {
  padding: 3rem 2rem;
}

/* Reuse all other styles from PrivacyPolicy.css */
/* Add specific term-related styles if needed below */

/* Mobile Accordion */
.mobile-accordion .accordion-section {
  margin-bottom: 1rem;
  border: 2px solid var(--border-color);
}

/* Desktop Layout */
.desktop-layout {
  display: grid;
  grid-template-columns: 280px 1fr;
}

/* Footer */
.terms-footer {
  background-color: var(--primary-color);
  color: white;
  padding: 3rem 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .terms-header {
    padding: 3rem 1.5rem;
  }
  
  .terms-header h1 {
    font-size: 2rem;
  }
}