/* src/components/PromptList.css */

.prompt-card {
    background-color: #FFFFFF; /* Dark grey background */
    padding: 15px;         /* Good padding */
    margin-bottom: 15px;   /* Space between cards */
    /* margin-left: 4px;
    margin-right: 15px; */
    margin-left: auto;    /* Changed from 4px */
    margin-right: auto;   /* Changed from 15px */
    color: white;          /* White text */
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 18px;
    align-items: flex-start; /* Left-aligned content */
    width: 90%;
    max-width: 600px;     /* Optional: add a max-width for larger screens */
}

.prompt-card-skeleton {
    background-color: #000;
    padding: 10px;         /* Good padding */
    margin-bottom: 15px;   /* Space between cards */
    margin-left: 15px;
    margin-right: 15px;
    border:  0.6px solid #000000;
    
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    align-items: flex-start; /* Left-aligned content */
   
}

.prompt-title {
    /* font-weight: bolder; */
    /* Bold title */
    font-weight: 800;
    font-size: larger;
    color: #000;
    margin-bottom: 8px;   /* Space between title and description */
    font-family:  "Inter";
    font-weight: 900;
    padding-right: 5px;
    padding-left: 5px;
}

.prompt-description {
    font-size: 1.1rem;       /* Moderate font size for description */
    font-family: "Inter";
    font-weight: 500;
    line-height: 22.21px;
    padding-right: 2px;
    padding-left: 5px;
    margin-bottom: 5px;

    color: #545454;
}
